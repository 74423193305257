import React, { Component } from 'react';
import { Progress, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import request from 'superagent';

class DropPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCode: props.showCode,
            pending: false,
            cropUrlResult: null,
            apiURL: props.apiURL,
            title: props.title,
            error: null,
            code: ''
        };
        this.onDrop = this.onDrop.bind(this);
    }
    onDrop(files) {
        this.setState({ cropUrlResult: null, pending: true });
        var file = files[0];
        var that = this;
        request.post(this.state.apiURL).attach("files", file, file.name).set('X-Fbatech-Code', this.state.code).end(function (err, response) {
            var data = JSON.parse(response.text);

            if (data.fields && data.fields.cropUrlResult) {
                that.setState({ cropUrlResult: data.fields.cropUrlResult, pending: false });
            } else {
                that.setState({ error: data.error.message, pending: false });
            }
        });
        console.log('Received files: ', files);
    }
    handleCode(e) {
        this.setState({ ...this.state, code: e.target.value });
    }
    render() {
        return <div>
            <h2 className="text-center add_margin_bottom_20">{this.state.title}</h2>
            {this.state.showCode && <div>
                <div>Crop Code: <input type="text" name="code" value={this.state.code} onChange={this.handleCode.bind(this)}></input></div>
            </div>}
            <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="DropPDF center-block">
                        <div className="center-block text-center add_color_general">
                        </div>
                        <div className="text-center add_color_general">
                            <p>{this.props.txt}</p>
                            <p>(Click to browse)</p>
                        </div>
                        <input {...getInputProps()} />
                    </div>)}
            </Dropzone>
            {this.state.pending && <div id="pendingResult" className={"center-block"}>
                <Progress active now={45} />
                <p>Processing ...</p>
            </div>}
            {this.state.cropUrlResult && <div id="cropResult" className={"center-block"}>
                <h3 className="success_message add_margin_bottom_30">Done! Your cropped file is ready.</h3>
                <Button className="btn-jumbo" href={this.state.cropUrlResult}>Download result</Button>
            </div>}
            {this.state.error && <div id="cropResult" className={"center-block"}>
                <h3 className="failure_message add_margin_bottom_30">{this.state.error}</h3>
            </div>}
        </div>
    }
};
export default DropPDF;
