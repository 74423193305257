import React, { Component } from "react";
import "./App.css";
import Home from "./Home";
import { Container, Row, Col } from "reactstrap";
import DropPDF from "./DropPDF";
import logo from "./fbatech_logo.png";

const API_SERVER = "https://storage.fbatech.com";
// const API_SERVER = "http://localhost:3000";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Container>
          <Row>
            <Col xs={12} sm={8}>
              <img src={logo}></img>
            </Col>
            <Col xs={12} sm={2} className="top-align-text">
              <p className="text-center-xs float-right-sm">
                <a href="mailto:info@fbatech.com">Email Us</a>
              </p>
            </Col>
            <Col xs={12} sm={2} className="text-center">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" className="add_margin_bottom_10">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="EWEPDHXDHZ3WE" />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>

            </Col>
          </Row>
          <Row>
            <Home></Home>
          </Row>
          <Row>
          </Row>
          <Row id="eBayMercariPoshmarkDrop" className="center-block text-center">
            <Col lg={{ size: 12 }} lg={{ size: 6, offset: 3 }}>
              <DropPDF
                apiURL={`${API_SERVER}/api/eBayMercariPoshmarkLabels/upload`}
                title="eBay, Mercari, Poshmark, Pirate Ship"
                txt="Drop your eBay, Mercari, Poshmark, or Pirate Ship PDF here"
                showCode={true}
              ></DropPDF>
            </Col>
          </Row>
          <hr />
          <Row id="AmazonFBADrop" className="center-block text-center">
            <Col xs={12} md={6}>
              <DropPDF
                apiURL={`${API_SERVER}/api/AmazonUPSFBALabels/upload`}
                title="Amazon UPS FBA"
                txt="Drop your Amazon FBA UPS PDF here"
              ></DropPDF>
            </Col>
            <Col xs={12} md={6}>
              <DropPDF
                apiURL={`${API_SERVER}/api/AmazonFedexFBALabels/upload`}
                title="Amazon Fedex FBA"
                txt="Drop your Amazon FBA Fedex PDF here"
              ></DropPDF>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <h1>FAQ</h1>
              <ol>
                <li>
                  <p className="lead">Q: Why are you called FBATech?</p>
                  <p>
                    A: I originally designed the tools for this site to help me process shipping labels for fulfillment by amazon which is where the FBA comes from.  My wife often sold things on poshmark and mercari so I added the ability to crop those labels too.  Now the A stands for "ant" which is my nickname!  Fulfillment by ant Tech.
                  </p>
                </li>
                <li>
                  <p className="lead">Q: Which eCommerce platforms do you support?</p>
                  <p>
                    A: The site currently supports Amazon, eBay, Mercari, Poshmark, and Pirate Ship labels.  It might actually support more platforms than listed because it was designed to find boxes and automatically crop those boxes.  If it is not working for you, please let me know via info@fbatech.com and I can look into making it work for you!
                  </p>
                </li>
                <li>
                  <p className="lead">Q: What is a thermal printer?</p>
                  <p>
                    A: A thermal printer is a device that uses heat to mark special paper which results in a black
                    marking. It is what the professionals use for shipping labels because it is fast, dependable,
                    economic, and results in a mostly waterproof label. Compare that to printing on an inkjet printer
                    where you'd want to tape over every square inch of paper to make sure rain doesn't smudge your
                    label. Thermal printer consumables are primarily the label that you're printing on. Other common
                    printers like inkjet use ink or laser printers use toner.
                  </p>
                </li>
                <li>
                  <p className="lead">Q: What thermal printer do you recommend?</p>
                  <p>
                    A: I use the Zebra GK420d. It is the network version so I can print from multiple computers which is
                    quite handy. It works with both Mac and Windows. It has been very reliable and I have printed over
                    600 labels without a problem.
                  </p>
                  <p>Other users have suggested the DYMO thermal printers as a more economic option.</p>
                </li>
                <li>
                  <p className="lead">Q: Why did you make this site?</p>
                  <p>
                    A: I made this tool for myself as it was time consuming to manually crop shipping label PDFs. After
                    I automated the process, I decided to share the functionality through this website so that other
                    people can benefit from my work.
                  </p>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;
