import React, { Component } from 'react';
import { Jumbotron, Button } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

class Home extends Component {
    render() {
        return <Jumbotron>
            <h1 className="text-center add_margin_bottom_30">drag and drop crop</h1>
            <p className="lead add_margin_bottom_60">We provide a quick drag and drop solution to save you time by cropping Amazon FBA, eBay, Mercari, Poshmark, and Pirate Ship shipment PDFs so that you can thermal print directly to a 4x6 shipping label.</p>
            <p className="lead add_margin_bottom_60">Subscribe to a FBATECH subscription to get a Crop Code and crop at least 250 labels per month with no daily limit.  Don't need to crop that many but want to support this site anyways? Subscribe!<br />
                <Button className="btn-jumbo" href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8NB00595SX6729538MTYUEHA" target="_blank">Subscribe</Button><br />
                Need more than 250 a month? Send an e-mail to <a href="mailto:info@fbatech.com?subject=FBATECH Crop Code">info@fbatech.com</a> to discuss.</p><br />
            <Container>
                <Row>
                    <Col xs={12} md={4}>
                        <Button className="btn-jumbo btn-warning" href="#eBayMercariPoshmarkDrop">Crop eBay, Mercari, Poshmark, and Pirate Ship Label</Button>
                    </Col>
                    <Col xs={12} md={4}>
                        <Button className="btn-jumbo btn-warning" href="#AmazonFBADrop">Crop Amazon FBA UPS</Button>
                    </Col>
                    <Col xs={12} md={4}>
                        <Button className="btn-jumbo btn-warning" href="#AmazonFBADrop">Crop Amazon FBA Fedex</Button>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    }
};

export default Home;